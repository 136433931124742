import React, {useEffect,useState} from "react";
import IMAGES from "../static/images"

function ResearchAnalystAnimation() {
  useEffect(() => {
    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target.id === "chain-connect") {
            setTimeout(() => {
              entry.target.classList.add('animate');
              observer.unobserve(entry.target);
            }, 2000)
          } else if (entry.target.id === "complete-thought-tick") {
            setTimeout(() => {
              entry.target.classList.add('animate');
              observer.unobserve(entry.target);
            }, 3500)
          } else if (entry.target.id === "bar1" || entry.target.id === "bar2" || entry.target.id === "bar3" || entry.target.id === "report-ready-bar" || entry.target.id === "report-ready-text") {
            setTimeout(() => {
              entry.target.classList.add('animate');
              observer.unobserve(entry.target);
            }, 5000)
          } else if (entry.target.id === "report-ready-check") {
            setTimeout(() => {
              entry.target.classList.add('animate');
              observer.unobserve(entry.target);
            }, 6500)
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, { threshold: 0.2 });

    const elements = document.querySelectorAll('.animated-element');
    elements.forEach(element => {
      observer.observe(element);
    });

    return () => {
      elements.forEach(element => {
        observer.unobserve(element);
      });
    };
  }, []);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isDesktop) {
    return (
      <div className="my-10 bg-[#01101D] grid grid-cols-2 gap-x-10 p-32 px-40 max-[1400px]:px-32 max-[1200px]:px-24 mx-20 rounded-3xl relative">
        <div className="ra-animation-bg absolute top-[50%] left-[10%] translate-y-[-50%] h-[50%] w-[40%]"></div>
        <div className="absolute right-0">
          <img src={IMAGES.grid} alt="" />
        </div>
        <div className="w-full flex justify-center items-center mt-10">
          <div className="h-full w-[85%] ra-animation-base relative">
            <div className="h-[2px] w-[5%] bg-[#00C0FF] absolute top-[3vw] left-[0.5vw]"></div>
            <div className="w-[25%] font-poppins-regular text-[#0FF] text-[1vw] text-left absolute top-[4vw] left-[0.5vw]">
              FINANCIAL MARKET DATA
            </div>
            <div className="w-[28%] h-[10vh] absolute top-[10vw] left-[0.5vw] flex flex-row">
              <div id="bar1" className="w-[23%] h-[10vh] bg-[#00FFFF] mx-1 place-self-end animated-element"></div>
              <div id="bar2" className="w-[23%] h-[3vh] bg-[#00FFFF] mx-1 place-self-end opacity-70 animated-element"></div>
              <div id="bar3" className="w-[23%] h-[7vh] bg-[#00FFFF] mx-1 place-self-end opacity-50 animated-element"></div>
            </div>
            <div className="h-full w-[69%] ra-animation-phone absolute right-0 top-[-60px] p-1">
              <div className="text-white font-poppins-regular text-left p-5 text-[1vw]">
                Chain of thought
              </div>
              <div className="bg-[#68B1F3] text-white text-[0.75vw] p-2 mx-5 text-left rounded-sm relative mb-5">
                What are GDP numbers for ...
                <div className="bg-[#FF9800] rounded-full h-[2vh] w-[2vh] absolute right-[-1vh] translate-y-[-100%]"></div>
              </div>
              <div className="bg-[#68B1F3] text-white text-[0.75vw] p-2 mx-5 text-left rounded-sm relative mt-5">
                Data Viz: Compare last 15 yrs .....
                <div className="bg-[#FF9800] rounded-full h-[2vh] w-[2vh] absolute right-[-1vh] translate-y-[-100%]"></div>
              </div>
              <div id="chain-connect" className="animated-element"></div>
              <div className="bg-white text-black text-[1vw] p-3 mx-5 text-center rounded-sm relative mt-[3vw] font-poppins-bold border-cyan-400 border-2">
                Complete Thought
                <div id="complete-thought-tick" className="bg-[#FF9800] rounded-full h-[2vw] w-[2vw] absolute right-[-1vw] translate-y-[-90%] flex justify-center items-center animated-element text-left overflow-hidden">
                  <i className="fa-sharp fa-solid fa-check text-white"></i>
                </div>
              </div>
            </div>
            <div className="animation-end w-full absolute bottom-0 h-16 text-left p-3 flex flex-row items-center">
              <i className="fa-solid fa-file-pdf text-white mr-3"></i>
              <div id="report-ready-bar" className="h-[50%] w-1 bg-white absolute animated-element"></div>
              <div id="report-ready-text" className="text-white font-poppins-regular pl-3 animated-element max-h-6 w-full text-[1vw] max-[1200px]:text-[0.75vw]">
                Report ready
              </div>
              <div className="rounded-full h-[6vw] w-[6vw] tick-circle border-white flex justify-center items-center ml-5 absolute left-[15vw]">
                <i id="report-ready-check" className="fa-sharp fa-solid fa-check text-white animated-element text-[1vw]"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-5">
          <div className="flex flex-col text-left">
            <div className="font-poppins-extraBold text-[2.5vw] free-trial-heading mb-5">
              Collaborate to build high quality reports at light speed
            </div>
            <div className="font-poppins-regular text-[1vw] text-[#C2E3FF] my-5">
              NeoGPT separates chain of thought from the PDF report outputs allowing teams to collaborate, version and iterate on reports.
            </div>
            <a href="mailto:anuj@onfinance.in?subject=NeoGPT%20Free%20Trial&body=Please%20activate%20a%20NeoGPT%20free%20trial%20version%20for%20my%20organization.%20Regards">
              <button className="bg-white text-center rounded-2xl font-poppins-regular font-semibold text-[1.25vw] px-9 py-5 mt-5 w-fit">
                <h3 className="ra-get-trial-btn">Get Free Trial</h3>
              </button>
            </a>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="my-10 bg-[#01101D] py-16 relative">
        <div className="ra-animation-bg absolute top-[50%] left-[10%] translate-y-[-50%] h-[50%] w-[40%]"></div>
        <div className="absolute right-0">
          <img src={IMAGES.grid} alt="" />
        </div>
        <div className="mx-5">
          <div className="flex flex-col text-center">
            <div className="font-poppins-extraBold text-[8vw] free-trial-heading mb-5">
              Collaborate to build high quality reports at light speed
            </div>
            <div className="font-poppins-regular text-[6vw] text-[#C2E3FF] my-5">
              NeoGPT separates chain of thought from the PDF report outputs allowing teams to collaborate, version and iterate on reports.
            </div>
            <a href="mailto:anuj@onfinance.in?subject=NeoGPT%20Free%20Trial&body=Please%20activate%20a%20NeoGPT%20free%20trial%20version%20for%20my%20organization.%20Regards">
              <button className="bg-white text-center rounded-2xl font-poppins-regular font-semibold text-[5vw] px-9 py-5 mt-5">
                <h3 className="ra-get-trial-btn">Get Free Trial</h3>
              </button>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default ResearchAnalystAnimation