function Footer() {
  return (
    <div className="min-[850px]:py-6 min-[850px]:px-[8%]">
      <div className="footer-block">
        <div className="container-small mx-auto">
          <div className="footer-grid ">
            <div className="grid-component text-left max-[850px]:text-center ">
              <a
                href="https://onfinance.ai/"
                className="footer-logo inline-block max-w-full"
              >
                <img
                  src="https://uploads-ssl.webflow.com/6448f35ca6a5ea361d6dc3a6/644bb198cbe59b07c9357a61_Group%2048096163.png"
                  loading="lazy"
                  width="200"
                  sizes="(max-width: 479px) 100vw, 285.99609375px"
                  alt=""
                  srcSet="https://uploads-ssl.webflow.com/6448f35ca6a5ea361d6dc3a6/644bb198cbe59b07c9357a61_Group%2048096163-p-500.png 500w, https://uploads-ssl.webflow.com/6448f35ca6a5ea361d6dc3a6/644bb198cbe59b07c9357a61_Group%2048096163-p-800.png 800w, https://uploads-ssl.webflow.com/6448f35ca6a5ea361d6dc3a6/644bb198cbe59b07c9357a61_Group%2048096163-p-1080.png 1080w, https://uploads-ssl.webflow.com/6448f35ca6a5ea361d6dc3a6/644bb198cbe59b07c9357a61_Group%2048096163-p-1600.png 1600w, https://uploads-ssl.webflow.com/6448f35ca6a5ea361d6dc3a6/644bb198cbe59b07c9357a61_Group%2048096163-p-2000.png 2000w, https://uploads-ssl.webflow.com/6448f35ca6a5ea361d6dc3a6/644bb198cbe59b07c9357a61_Group%2048096163-p-2600.png 2600w, https://uploads-ssl.webflow.com/6448f35ca6a5ea361d6dc3a6/644bb198cbe59b07c9357a61_Group%2048096163-p-3200.png 3200w, https://uploads-ssl.webflow.com/6448f35ca6a5ea361d6dc3a6/644bb198cbe59b07c9357a61_Group%2048096163.png 3866w"
                  className="logo max-w-full inline-block "
                />
              </a>
              <p className="paragraph-small text-gray-300 text-left ">
                MINDWEAVE TRANSFORMERS PRIVATE LIMITED
                <br />
                Reg Office: #677, 1st Flr, 27th main, 13th cross, HSR
                L/o,1Sector HSR Layout Bangalore South Bangalore KA 560102 IN
                <br />
                CIN: U62099KA2023PTC173821
                <br />
                Email: info@onfinance.in
                <br />
                Mob No:+91-7233089282
              </p>
            </div>
            <div className="grid-component footer-link-grid grid ">
              <div className="grid-component footer-link-column text-left max-[850px]:text-center">
                <div className="footer-title text-left">Pages</div>
                <a
                  href="https://www.onfinance.ai/#feature"
                  aria-current="page"
                  className="footer-link text-left"
                >
                  Features
                </a>
                <a
                  href="https://www.onfinance.ai/#pricing"
                  className="footer-link text-left"
                >
                  Pricing
                </a>
                <a
                  href="https://research.onfinance.ai"
                  className="footer-link text-left"
                >
                  Research
                </a>
                <a
                  href="https://sales.onfinance.ai"
                  className="footer-link text-left"
                >
                  Sales Team
                </a>
                <a
                  href="https://cs.onfinance.ai"
                  className="footer-link text-left text-left"
                >
                  Customer Success
                </a>
              </div>
              <div className="grid-component footer-link-column text-left max-[850px]:text-center">
                <div className="footer-title text-left">Company</div>
                <a
                  href="https://onfinance.ai"
                  className="footer-link text-left"
                >
                  NeoGPT
                </a>
                <a
                  href="https://onfinance.ai/#contact"
                  className="footer-link text-left"
                >
                  Contact
                </a>
              </div>
            </div>
            <div className="footer-social-links">
              <a
                href="https://www.facebook.com/OnFinance.in"
                className="footer-social-link max-w-full"
              >
                <img
                  src="https://uploads-ssl.webflow.com/6448f35ca6a5ea361d6dc3a6/644bb3275eb17a9eca67d1b2_Group.png"
                  loading="lazy"
                  alt=""
                  className="w-5 max-w-full inline-block"
                />
              </a>
              <a
                href="https://www.instagram.com/onfinance.in/"
                className="footer-social-link max-w-full"
              >
                <img
                  src="https://uploads-ssl.webflow.com/6448f35ca6a5ea361d6dc3a6/644bb326362aa469a46efb16_Vector.png"
                  loading="lazy"
                  alt=""
                  className="w-5 max-w-full inline-block"
                />
              </a>
              <a
                href="https://twitter.com/OnFinance_in"
                className="footer-social-link max-w-full"
              >
                <img
                  src="https://uploads-ssl.webflow.com/6448f35ca6a5ea361d6dc3a6/644bb327be660bf043572b19_Group-1.png"
                  loading="lazy"
                  alt=""
                  className="w-5 max-w-full inline-block"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/onfinanceofficial/"
                className="footer-social-link max-w-full"
              >
                <img
                  src="https://uploads-ssl.webflow.com/6448f35ca6a5ea361d6dc3a6/644bb326f19b8432fb849469_Group-2.png"
                  loading="lazy"
                  alt=""
                  className="w-5 max-w-full inline-block"
                />
              </a>
              <a
                href="https://www.youtube.com/@OnFinanceHQ"
                className="footer-social-link max-w-full"
              >
                <img
                  src="https://uploads-ssl.webflow.com/6448f35ca6a5ea361d6dc3a6/644bb32741cd4865943a024c_Group-3.png"
                  loading="lazy"
                  alt=""
                  className="w-5 max-w-full inline-block"
                />
              </a>
            </div>
          </div>
          <div className="footer-divider"></div>
          <div className="footer-bottom">
            <div className="paragraph-small">
              Copyright © OnFinance 2023-2024
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
