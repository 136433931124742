import DataVizCard from "./DataVizCard";

function DataVizTools({ dataViz }) {
  const len = dataViz.imgPaths.length;
  console.info(dataViz.imgPaths[0])
  const row1 = dataViz.imgPaths.slice(0, len / 2);
  const row2 = dataViz.imgPaths.slice(len / 2);
  return (
    <div className="bg-[#01101D] w-full my-20 py-32 px-0 ra-dataviz-bg">
      <div className="font-poppins-bold text-[3vw] max-[850px]:text-[5vw] py-2 ra-dataviz-heading">
        {dataViz.heading}
      </div>
      <div className="font-poppins-regular text-[1.25vw] max-[850px]:text-[3vw] text-center text-white pb-20">
        {dataViz.description}
      </div>
      <div className="data-viz">
        <div className="row1 mt-10">
          {row1.map((imgPath) => (
              <DataVizCard imgPath={imgPath}></DataVizCard>
          ))}
        </div>
        <div className="row1 mt-10">
          {row1.map((imgPath) => (
              <DataVizCard imgPath={imgPath}></DataVizCard>
          ))}
        </div>
      </div>
      <div className="data-viz">
        <div className="row2 flex flex-row mb-10">
          {row2.map((imgPath) => (
            <DataVizCard imgPath={imgPath}></DataVizCard>
          ))}
        </div>
        <div className="row2 flex flex-row mb-10">
          {row2.map((imgPath) => (
            <DataVizCard imgPath={imgPath}></DataVizCard>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DataVizTools;
