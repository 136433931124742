import Card from "./Card";
import FeatureCard from "./FeatureCard";
import FeatureItems from "./FeatureItems";
import IntroDescription from "./IntroDescription";
import IntroHeader from "./IntroHeader";
import IntroTestimonial from "./IntroTestimonial";
import ResearchAnalystAnimation from "./ResearchAnalystAnimation";

function Body({ team }) {
  return (
    <div className="inline-grid min-[850px]:px-[8%]">
      <div className="py-[5%] pl-[5%] pr-[3%] max-[850px]:px-[8%]">
        <div className="min-[850px]:grid min-[850px]:grid-cols-[1fr_0.5fr_0.85fr] justify-between">
          <IntroHeader headingText={team.intro.heading} />
          <IntroDescription descriptionText={team.intro.description} />
        </div>
      </div>
      <div className="min-[850px]:relative w-full py-20 min-[850px]:mb-20">
        <img
          className="mx-auto dashboard w-full min-[850px]:scale-110"
          src={team.dashboardImg}
          alt={team.dashboardImgAlt}
        />
        <div className="mx-[10%] min-[850px]:absolute min-[850px]:bottom-0 min-[850px]:grid min-[850px]:grid-cols-3 min-[850px]:gap-x-10 max-[1050px]:gap-x-4 max-[950px]:gap-x-3 max-[850px]:gap-x-2">
          <IntroTestimonial
            testimonialMessage={team.testimonial.message}
            avatarIconPath={team.testimonial.profileIcon}
            avatarName={team.testimonial.profile}
            avatarCompany={team.testimonial.company}
          ></IntroTestimonial>
          <FeatureItems
            windowWidth={window.innerWidth}
            features={team.features}
          />
        </div>
      </div>
      <ResearchAnalystAnimation />
      <div className="mx-[10%] my-20 max-[850px]:my-10">
        <div className="mx-auto font-poppins-bold text-[3.5vw] max-[850px]:text-[8vw] mb-10">
          <h1 className="inline ra-hash">#</h1> {team.copilot.heading}
        </div>
        <div className="mx-auto font-poppins-regular font-semibold text-[1.5vw] max-[850px]:text-[6.5vw]">
          {team.copilot.description}
        </div>
      </div>
      <Card
        cardHeading={team.card.heading}
        feature1ImgPath={team.card.benefit1.img}
        feature1ImgAlt={team.card.benefit1.imgAlt}
        feature1Heading={team.card.benefit1.heading}
        feature1Description={team.card.benefit1.description}
        feature2ImgPath={team.card.benefit2.img}
        feature2ImgAlt={team.card.benefit2.imgAlt}
        feature2Heading={team.card.benefit2.heading}
        feature2Description={team.card.benefit2.description}
        card1Img={team.card.imgPath}
        card1ImgAlt={team.card.imgAlt}
      ></Card>
      <div className="min-[850px]:grid min-[850px]:grid-cols-2 min-[850px]:gap-x-12 min-[850px]:my-16 mx-auto px-[8%]">
        <FeatureCard
          heading={team.featureCard1.heading}
          description={team.featureCard1.description}
          imgPath={team.featureCard1.img}
          imgAlt={team.featureCard1.imgAlt}
        ></FeatureCard>
        <FeatureCard
          heading={team.featureCard2.heading}
          description={team.featureCard2.description}
          imgPath={team.featureCard2.img}
          imgAlt={team.featureCard2.imgAlt}
        ></FeatureCard>
      </div>
    </div>
  );
}

export default Body;
