import Blog from "../components/Blog";
import Body from "../components/Body";
import DataVizTools from "../components/DataVizTools";
import Footer from "../components/Footer";
import Navbar from "../components/NavBar";
import constants from "../constants";

function ResearchAnalyst() {
  return (
    <div>
      <Navbar />
      <Body team={constants.researchAnalyst} />
      <DataVizTools dataViz={constants.researchAnalyst.dataViz}></DataVizTools>
      <Blog
        blog={constants.researchAnalyst.blog}
        team={constants.researchAnalyst.team}
      ></Blog>
      <Footer />
    </div>
  );
}

export default ResearchAnalyst;
