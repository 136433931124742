import React, { useState, useEffect } from 'react';

function BlogCard(
  {
    imgPath,
    heading,
    date,
    profile,
    url
  }
) {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 850);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 850);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    isDesktop
    ?
    <a href={url} target="blank">
      <div className="rounded-xl flex flex-col p-5 h-full ra-blog-card-bg">
        <img className="rounded-xl pb-5 w-full h-auto" src={imgPath} alt="" />
        <div className="font-poppins-bold text-[2vw] md:text-[1.5vw] pb-16 pt-6 text-left ra-blog-card-heading">
          {heading}
        </div>
        <div className="grid grid-cols-2 mt-auto place-items-baseline relative">
          <div className="font-poppins-regular text-[1.25vw] text-left ra-blog-card-date">
            {date}
          </div>
          <div className="absolute bottom-0 right-0">
            <img className="rounded-full h-[65%] w-[65%] ml-auto" src={profile} alt="" />
          </div>
        </div>  
      </div>
    </a>
    :
    <a href={url} target="blank">
      <div className="rounded-xl flex flex-row items-center w-full p-5 my-10 ra-blog-card-bg">
        <img className="rounded-xl w-[50%]" src={imgPath} alt="" />
        <div className="font-poppins-bold text-md md:text-xl text-left pl-5 ra-blog-card-heading">
          {heading}
        </div> 
      </div>
    </a>
  )
}

export default BlogCard