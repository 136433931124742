const constants = {
  getFreeTrialButtonText: "Get Free Trial",
  watchDemoButtonText: "Watch Demo",
  researchAnalyst: {
    team: "Research Analyst",
    intro: {
      heading: "Gen AI assistant for Research Analysts",
      description: "Use NeoGPT to assist your analyst teams with everything from report generation from chain"
    },
    dashboardImg: require("./static/images/research-analysts-dashboard.png"),
    dashboardImgAlt: "Research Analysts Dashboard",
    testimonial: {
      message: "“Send about million emails business, It's really important personal having newsletter”",
      profile: "Andrew Jack",
      company: "Motilal Oswal",
      profileIcon: require("./static/images/jack-icon.png")
    },
    features: {
      item1: "Report Generation",
      iconItem1: require("./static/images/report-generation.png"),
      item2: "Dataset Insights",
      iconItem2: require("./static/images/dataset-insights.png"),
      item3: "Inference on Graphs",
      iconItem3: require("./static/images/inference-on-graphs.png"),
      item4: "Compliance Checks",
      iconItem4: require("./static/images/compliance-checks.png"),
    },
    animation: {
      heading: "Collaborate to build high quality reports at light speed",
      description: "NeoGPT separates chain of thought from the PDF report outputs allowing teams to collaborate, version and iterate on reports."
    },
    copilot: {
      heading: "1 copilot for research analysts",
      description: "Leverage generative AI within your existing tools and workflows to supercharge your workflows 10x and be more efficient."
    },
    card: {
      heading: "Automatically generate reports from chain of thought",
      imgPath: require("./static/images/report-generation-stat.png"),
      imgAlt: "Report Generation",
      benefit1: {
        heading: "Easy to version and collaborate",
        description: "Feel some pages are not upto the mark delete that node and start building again.",
        img: require("./static/images/collaborate-icon.png"),
        imgAlt: "Collaborate"
      },
      benefit2: {
        heading: "Natural Language Query",
        description: "Seamlessly ask questions that encompasses multiple enterprise datasets & public data.",
        img: require("./static/images/natural-language-query-icon.png"),
        imgAlt: "Natural Language Query"
      }
    },
    featureCard1: {
      heading: "Inference from Chart Analysis",
      description: "Analyze the what different charts are talking about & where the interpretation of the charts are useful such as in follow-on questions",
      img: require("./static/images/chart-analysis.png"),
      imgAlt: "Chart Analysis"
    },
    featureCard2: {
      heading: "AI-powered Compliance Checks",
      description: "Automatically ensures your report passes the necessary compliance requirements before being generated else watermarks slides as non-compliant",
      img: require("./static/images/compliance-checklist.png"),
      imgAlt: "Compliance checklist"
    },
    dataViz: {
      heading: "Integrated with the best Data Visualization tools",
      description: "Along with the 10+ datastores and 100+ public data sources like news sites and public filings",
      imgPaths: [
        require("./static/images/tableau.png"),
        require("./static/images/powerBI.png"),
        require("./static/images/zoho-books.png"),
        require("./static/images/excel.jpg"),
        require("./static/images/ibm-watson.png"),
        require("./static/images/dundasBI.png"),
        require("./static/images/google-developers-charts.png"),
        require("./static/images/fusion-charts.png"),
        require("./static/images/infogram.png"),
        require("./static/images/jupyter.png"),
        require("./static/images/sisense.png"),
        require("./static/images/visual-ly.png"),
        require("./static/images/qlik-view.png")
      ]
    },
    blog: {
      heading: "Resources for research analyst teams",
      btn1: "All",
      btn2: "Team Leads",
      btn3: "Workflows",
    }
  }
}

export default constants